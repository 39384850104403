import React, {useEffect, useMemo, useRef, useState} from "react";
import DialogPortal from "../../components/DialogPortal";
import Slider from "../../components/Slider";
import CheckBox from "../../components/CheckBox";
import LocationDataGrouped from "../../generated/interfaces/locationDataGrouped";
import DashboardFarmDisplay from "../../generated/interfaces/dashboardFarmDisplay";
import {renderLayers} from "../mapFunctions";
import GoogleMaps from "../GoogleMaps";
import {GoogleMapsOverlay as DeckOverlay} from "@deck.gl/google-maps/typed";
import GL from '@luma.gl/constants';
import mapstyle from "../mapstyle";
import {onEscape} from "../../helpers/keys";

function buildDeckOverlay(points: LocationDataGrouped[]): DeckOverlay {
    return new DeckOverlay({
        parameters: {
            // Additive blending
            blendFunc: [GL.SRC_ALPHA, GL.ONE, GL.ONE, GL.ONE_MINUS_SRC_ALPHA]
        },
        layers: renderLayers(points, {coverage: 1, radius: 2.5, upperPercentile: 100})
    });
}

export function getPosition(points: LocationDataGrouped[]) {
    return {
        lat: points.length > 0 ? points[0]!.lat : 0,
        lng: points.length > 0 ? points[0]!.lon : 0
    }
}

const DeckGLMap: React.FC<{
    setShow: (v: boolean) => void;
    points: LocationDataGrouped[];
    displayData: any;
    selectedFarm: DashboardFarmDisplay;
    landsArgsSeasonId: number | null;
}> = (props) => {

    const [radius, setRadius] = useState<number>(2.5)
    const [followMe, setFollowMe] = useState(false)

    const mapRef = useRef<google.maps.Map>()
    const deckRef = useRef<DeckOverlay>();
    const deviceMarkerRef = useRef<google.maps.Marker>();
    const watchIdRef = useRef<number | null>(null);

    function area() {
        return (2.59807621 * Math.pow(radius, 2)).toFixed(2)
    }

    useEffect(() => {
        deckRef.current = buildDeckOverlay(props.points);
        if (mapRef.current) {
            mapRef.current?.overlayMapTypes.clear();
            deckRef.current.setMap(mapRef.current);
        }
    }, [props.points])

    function closeMap() {
        props.setShow(false);
    }

    useEffect(() => {
        window.addEventListener('keydown', onEscape(closeMap));
        return () => {
            window.removeEventListener('keydown', onEscape(closeMap))
        }
    }, [])

    // Handle "Follow Me" functionality
    useEffect(() => {
        if (followMe) {
            if (navigator.geolocation) {
                watchIdRef.current = navigator.geolocation.watchPosition(position => {
                    const lat = position.coords.latitude;
                    const lng = position.coords.longitude;

                    if (mapRef.current) {
                        if (!deviceMarkerRef.current) {
                            deviceMarkerRef.current = new google.maps.Marker({
                                position: {lat, lng},
                                map: mapRef.current,
                                icon: {
                                    path: google.maps.SymbolPath.CIRCLE,
                                    scale: 6,
                                    fillColor: '#4285F4',
                                    fillOpacity: 1,
                                    strokeColor: '#ffffff',
                                    strokeWeight: 2
                                }
                            });
                        } else {
                            deviceMarkerRef.current.setPosition({lat, lng});
                        }

                        mapRef.current.setCenter({lat, lng});
                    }
                }, error => {
                    console.error('Error getting device position:', error);
                }, {
                    enableHighAccuracy: true,
                    maximumAge: 0,
                    timeout: 5000
                });
            } else {
                console.error('Geolocation is not supported by this browser.');
            }
        } else {
            // Stop watching position
            if (watchIdRef.current !== null) {
                navigator.geolocation.clearWatch(watchIdRef.current);
                watchIdRef.current = null;
            }

            // Remove the device marker
            if (deviceMarkerRef.current) {
                deviceMarkerRef.current.setMap(null);
                deviceMarkerRef.current = undefined;
            }
        }

        // Cleanup on component unmount
        return () => {
            if (watchIdRef.current !== null) {
                navigator.geolocation.clearWatch(watchIdRef.current);
                watchIdRef.current = null;
            }

            if (deviceMarkerRef.current) {
                deviceMarkerRef.current.setMap(null);
                deviceMarkerRef.current = undefined;
            }
        };
    }, [followMe]);

    const renderOnceMap = useMemo(() => {
        return <GoogleMaps
            initialize={map => {
                mapRef.current = map;
                deckRef.current?.setMap(mapRef.current);
            }}
            fullscreen={true}
            mapOptions={{
                center: getPosition(props.points),
                zoom: 17,
                styles: mapstyle,
                mapTypeId: 'satellite',
                streetViewControl: false,
                fullscreenControl: false,
                disableDefaultUI: true,
                tilt: 0
            }}/>
    }, [])

    return <DialogPortal>
        <div className="fixed inset-0 z-10">

            <div className="absolute top-0 left-0 bg-white p-2 m-2 z-10">
                <div>{props.displayData.farmName} {(props.displayData.blockName)} </div>
                <div className='flex items-center'>
                    <Slider value={radius} setValue={value => {
                        setRadius(value)
                        deckRef.current?.setProps({
                            layers: renderLayers(props.points, {
                                coverage: 1,
                                radius: value,
                                upperPercentile: 100
                            })
                        })
                    }}/>
                    <div className='ml-1'>Radius {radius} m ({area()}m<sup>2</sup>)</div>
                </div>
                <div className='flex items-center'>
                    <CheckBox checked={followMe} onChange={(v) => setFollowMe(v)}/>
                    <span className='ml-2'>Follow me</span>
                </div>
            </div>
            <div className="btn btn-error absolute top-2 right-2 p-2 z-10" onClick={() => props.setShow(false)}>X
            </div>
            {renderOnceMap}
        </div>
    </DialogPortal>
}

export default DeckGLMap;